import type { RouteLocationNormalizedGeneric } from 'vue-router';
import { SponsorType, type RegisterStep } from '~/composables/useRegisterSteps';

export default defineNuxtRouteMiddleware(
   async (
      to: RouteLocationNormalizedGeneric,
      from: RouteLocationNormalizedGeneric
   ) => {
      const { anonymousId } = useAnonymousClientStorage();
      const { getAnonymousData } = useClientAuthStore();
      const { isClientAuthenticated } = useAuthClientStorage();
      const { anonymousData } = storeToRefs(useClientAuthStore());
      if (isClientAuthenticated.value) return navigateTo('/client/booking');
      else if (anonymousId.value) {
         await getAnonymousData();
         switch (anonymousData.value?.sponsor) {
            case SponsorType.Insurance:
               if (!anonymousData.value.first_name)
                  return navigateTo('/auth/client/register/coverage');
               break;
            case SponsorType.Self:
            case SponsorType.Company:
               if (!anonymousData.value?.therapy_type)
                  return navigateTo('/auth/client/register/therapy-type');
               else if (anonymousData.value?.therapy_reasons.length < 1)
                  return navigateTo('/auth/client/register/therapy-reasons');
               else if (!anonymousData.value?.age_group) {
                  if (
                     anonymousData.value?.therapy_type.name == TherapyType.Teen
                  )
                     return navigateTo('/auth/client/register/parent-verified');
                  else return navigateTo('/auth/client/register/age-groups');
               } else if (
                  !anonymousData.value?.gender &&
                  anonymousData.value?.therapy_type.name != TherapyType.Teen
               )
                  return navigateTo('/auth/client/register/client-gender');
               else if (
                  anonymousData.value?.therapist_genders.length < 1 ||
                  anonymousData.value?.therapist_languages.length < 1
               )
                  return navigateTo(
                     '/auth/client/register/therapist-preference'
                  );
               break;
            default:
               return navigateTo('/auth/client/register/coverage');
         }
         return;
      } else {
         return navigateTo({ name: 'auth-client-login' });
      }
   }
);
